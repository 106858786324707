@import "../../assets/scss/variables";

.svg {
  opacity: 0.5;
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.default {
  padding: 10px 25px;
  margin: 10px;
  color: white;
  background: black;
  border-radius: 12px;
  border: none;
  outline: none;
  text-transform: capitalize;
  font-size: 1em;
  font-weight: bold;
  position: relative;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: hidden;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 2px 2px 0 rgba(0, 0, 0, 0.12);

  &:hover {
    background-color: #2b2929;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.18), 0 2px 2px 0 rgba(0, 0, 0, 0.15);
  }

  &:disabled {
    padding-right: 40px;
    color: white;
    cursor: not-allowed;
    background-color: #829fa1;

    &:hover {
      background-color: #698e91;
    }
  }
}

.small {
  @extend .default;

  margin: 0 5px 5px 0 !important;
  padding: 8px 15px;
  font-size: 0.75em;
  margin: 0;

  svg {
    @extend .svg;
  }
}

.medium {
  @extend .default;

  margin: 5px;
  font-size: 0.9em;

  svg {
    @extend .svg;
  }
}

.large {
  @extend .default;

  margin: 10px 0;
  font-size: 1.2em;

  svg {
    @extend .svg;
  }
}
